import _ from 'lodash';
import { DateTime } from 'luxon';

export interface paths {
  '/enkeltoppslag/kjoretoydata': {
    /** Henter kjoretoydata basert på understellsnummer, kjennemerke, eller personlig kjennemerke */
    get: operations['hentKjoretoydata'];
  };
}

export interface components {
  schemas: {
    AdrGodkjenning: {
      adrAnmerkninger?: string;
      adrAttestnummer?: string;
      adrBeskyttelseOverLastbarerKode?: components['schemas']['KodeverkType'];
      adrEX2EX3GodkjentGods?: string;
      adrKjoretoyLukket?: boolean;
      adrNasjonaleKrav?: boolean;
      adrPabyggTypeKode?: components['schemas']['KodeverkType'];
      adrTankTekniskeData?: components['schemas']['AdrTankTekniskeData'];
      adrTidligereBestemmelser?: boolean;
      adrTilleggsbremsEffekt?: string;
      adrTilleggsbremsIkkeAktuelt?: boolean;
      adrTransportorAdresse?: string;
      adrTransportorNavn?: string;
      adrTransportorPostnrSted?: string;
      adrTypeKode?: string[];
      adrVekselbyggID?: string;
      adrVekselbyggIDFra?: string;
      adrVekselbyggIDTil?: string;
    };
    AdrTankGodkjentGods: {
      adrTankGodkjentFor?: string;
      adrTankStofferIhhtTankkode?: boolean;
    };
    AdrTankInndelingVolum: {
      adrTankInndelingVolum?: number[];
    };
    AdrTankTekniskeData: {
      adrTankAntallRom?: number;
      adrTankFabrikat?: string;
      adrTankGodkjenningsnummer?: string;
      adrTankGodkjentGods?: components['schemas']['AdrTankGodkjentGods'][];
      adrTankInndeling?: components['schemas']['AdrTankInndelingVolum'];
      adrTankKode?: string;
      adrTankKofferdam?: boolean;
      adrTankLos?: boolean;
      adrTankNesteTetthetsproveDato?: string;
      adrTankNesteTrykkproveDato?: string;
      adrTankOverflyttetFra?: string;
      adrTankProduksjonsAr?: number;
      adrTankSerienummer?: string;
      adrTankSpesielleBestemmelser?: string;
      adrTankVolum?: number;
    };
    Aksel: {
      antallHjul?: number;
      avstandTilNesteAksling?: number;
      belastbar?: boolean;
      bremseAksel?: boolean;
      drivAksel?: boolean;
      egenvektAksel?: number;
      egenvektAkselMaks?: number;
      egenvektAkselMin?: number;
      fordelingAvTillattTotalvektAkselMaks?: number;
      fordelingAvTillattTotalvektAkselMin?: number;
      id?: number;
      loftbar?: boolean;
      luftfjaering?: boolean;
      maksAvstandTilNesteAksling?: number;
      maksimalSporvidde?: number;
      minAvstandTilNesteAksling?: number;
      minimalSporvidde?: number;
      plasseringAksel?: string;
      sporvidde?: number;
      styreAksel?: boolean;
      tekniskTillattAkselLast?: number;
      tekniskTillattAkselLastForhoyet?: number;
      tekniskTillattAkselLastVeg?: number;
    };
    AkselDekkOgFelg: {
      akselId?: number;
      belastningskodeDekk?: string;
      belastningskodeDekkTraktor?: string;
      dekkdimensjon?: string;
      felgdimensjon?: string;
      hastighetskodeDekk?: string;
      innpress?: string;
      tillattAkselLastTraktor?: number;
      tvilling?: boolean;
    };
    AkselDekkOgFelgKombinasjon: {
      akselDekkOgFelg?: components['schemas']['AkselDekkOgFelg'][];
      tillattTotalvektTraktor?: number;
    };
    AkselGruppe: {
      akselListe?: components['schemas']['AkselListe'];
      egenvektAkselGruppe?: number;
      egenvektAkselGruppeMaks?: number;
      egenvektAkselGruppeMin?: number;
      fordelingAvTillattTotalvektAkselGruppeMaks?: number;
      fordelingAvTillattTotalvektAkselGruppeMin?: number;
      id?: number;
      plasseringAkselGruppe?: string;
      tekniskTillattAkselGruppeLast?: number;
      tekniskTillattAkselGruppeLastVeg?: number;
    };
    AkselInfo: {
      akselGruppe?: components['schemas']['AkselGruppe'][];
      antallAksler?: number;
      forbindelseMellomDrivaksler?: components['schemas']['KodeverkType'];
    };
    AkselListe: {
      aksel?: components['schemas']['Aksel'][];
    };
    Bremse: {
      abs?: boolean;
      bremsesystem?: string;
      driftsbremsBak?: string;
      driftsbremsForan?: string;
      tilhengerBremseforbindelse?: string[];
      trykkMeterledningTilhengerkopling?: number;
      trykktilsluttning1?: number;
      trykktilsluttning2?: number;
    };
    BruktImportInfo: {
      importland?: components['schemas']['Importland'];
      kilometerstand?: number;
      tidligereUtenlandskKjennemerke?: string;
      tidligereUtenlandskVognkortNummer?: string;
    };
    DekkOgFelg: {
      akselDekkOgFelgKombinasjon?: components['schemas']['AkselDekkOgFelgKombinasjon'][];
      dekkOgFelgSidevogn?: components['schemas']['DekkOgFelgSidevogn'];
    };
    DekkOgFelgSidevogn: {
      belastningskodeDekkSidevogn?: string;
      dekkdimensjonSidevogn?: string;
      felgdimensjonSidevogn?: string;
      hastighetskodeDekkSidevogn?: string;
      innpressSidevogn?: string;
    };
    Dimensjoner: {
      bredde?: number;
      hoyde?: number;
      lengde?: number;
      lengdeInnvendigLasteplan?: number;
      maksimalBredde?: number;
      maksimalHoyde?: number;
      maksimalLengde?: number;
      maksimalLengdeInnvendigLasteplan?: number;
    };
    Drivstoff: {
      drivstoffKode?: components['schemas']['KodeverkType'];
      effektVektForhold?: number;
      maksEffektPrTime?: number;
      maksNettoEffekt?: number;
      maksNettoEffektVedOmdreiningstallMin1?: number;
      maksNettoEffektVedOmdreiningstallMin1Maks?: number;
      maksOmdreining?: number;
      spenning?: number;
      tomgangsOmdreiningstall?: number;
    };
    DyretransportGodkjenning: {
      fornyelse?: boolean;
      gulvareal?: number;
      hestetransporttype?: components['schemas']['KodeverkType'];
      takhoyde?: number;
    };
    EFTypegodkjenningsId: {
      typegodkjenningNrTekst?: string;
      typegodkjenningnummer?: components['schemas']['Typegodkjenningsnummer'];
      variant?: string;
      versjon?: string;
    };
    Fabrikant: {
      fabrikantAdresse?: string;
      fabrikantNavn?: string;
      fabrikantRepresentantAdresse?: string;
      fabrikantRepresentantNavn?: string;
    };
    ForbrukOgUtslipp: {
      antallPartikler?: number;
      co2BlandetKjoring?: number;
      co2Bykjoring?: number;
      co2Landeveiskjoring?: number;
      coProsent?: number;
      elEnergiforbruk?: number;
      forbrukBlandetKjoring?: number;
      forbrukBykjoring?: number;
      forbrukLandeveiskjoring?: number;
      malemetode?: components['schemas']['KodeverkType'];
      malemetodeAnnen?: string;
      partikkelfilterFabrikkmontert?: boolean;
      partikkelfilterUtslipp?: boolean;
      rekkeviddeKm?: number;
      utslippCOgPrKWh?: number;
      utslippCOmgPrKm?: number;
      utslippHCgPrKWh?: number;
      utslippHCgPrMin?: number;
      utslippHCmgPrKm?: number;
      utslippHCogNOxMgPrKm?: number;
      utslippKorrigertAbsorpsjonskoeffisient?: number;
      utslippNMHCmgPrKm?: number;
      utslippNOxGPrKWh?: number;
      utslippNOxMgPrKm?: number;
      utslippPartikkelAntallPrKm?: number;
      utslippPartiklerGPrKWh?: number;
      utslippPartiklerMgPrKm?: number;
      utslippTHCmgPrKm?: number;
      utslippTHCogNOxMgPrKm?: number;
      vektetKombinertDrivstoff?: number;
      vektetKombinertDrivstoffCO2?: number;
      wltpKjoretoyspesifikk?: components['schemas']['WLTP'];
      wltpTypegodkjenningMaks?: components['schemas']['WLTP'];
      wltpTypegodkjenningMedium?: components['schemas']['WLTP'];
      wltpTypegodkjenningMin?: components['schemas']['WLTP'];
    };
    ForenkletKjoretoyIdentitet: {
      kjennemerke?: string;
      understellsnummer?: string;
    };
    ForstegangsTekniskGodkjenning: {
      bruktimport?: components['schemas']['BruktImportInfo'];
      forstegangRegistrertDato?: string;
      fortollingOgMva?: components['schemas']['FortollingOgMva'];
      godkjenningsId?: string;
      godkjenningsundertype?: components['schemas']['KodeverkType'];
      gyldigFraDato?: string;
      gyldigFraDatoTid?: string;
      kvalitetskodeForstegangRegDato?: components['schemas']['KodeverkType'];
      oppbygdMedAvgiftsfritak?: components['schemas']['OppbygdMedAvgiftsfritak'];
      unntak?: components['schemas']['Unntak'][];
    };
    Forstegangsregistrering: {
      registrertForstegangNorgeDato?: string;
    };
    FortollingOgMva: {
      annenReferanse?: string;
      beskrivelse?: string;
      dokumentreferanse?: string;
      fortollingsreferanse?: string;
      linje?: number;
    };
    Generelt: {
      fabrikant?: components['schemas']['Fabrikant'][];
      ferdigbyggetEllerEndretSomFolger?: string;
      handelsbetegnelse?: string[];
      merke?: components['schemas']['Merke'][];
      tekniskKode?: components['schemas']['KodeverkType'];
      tekniskUnderkode?: components['schemas']['KodeverkType'];
      typebetegnelse?: string;
      unntakFra?: string;
    };
    Girutveksling: {
      girNummer?: string;
      girutveksling?: number;
    };
    Godkjenning: {
      forstegangsGodkjenning?: components['schemas']['ForstegangsTekniskGodkjenning'];
      kjoretoymerknad?: components['schemas']['Kjoretoymerknad'][];
      registreringsbegrensninger?: components['schemas']['Registreringsbegrensninger'];
      tekniskGodkjenning?: components['schemas']['TekniskGodkjenning'];
      tilleggsgodkjenninger?: components['schemas']['Tilleggsgodkjenning'][];
    };
    Importland: {
      landNavn?: string;
      landkode?: string;
    };
    KarosseriOgLasteplan: {
      antallDorer?: number[];
      avstandNavSkjermbueBak?: number;
      avstandNavSkjermbueForan?: number;
      bussKategori?: string;
      dorUtforming?: string[];
      fargeFjar?: string;
      forankringSikkerhetsseler?: string;
      forervern?: string;
      forervernBoyle?: string;
      godkjentADR?: string;
      hydrauliskLoft?: boolean;
      karosseriArt?: string;
      karosseritype?: components['schemas']['KodeverkType'];
      kjennemerketypeBak?: components['schemas']['KodeverkType'];
      kjennemerkestorrelseBak?: components['schemas']['KodeverkType'];
      kjennemerketypeForan?: components['schemas']['KodeverkType'];
      kjennemerkestorrelseForan?: components['schemas']['KodeverkType'];
      kjennemerketypeVenstre?: components['schemas']['KodeverkType'];
      kjennemerkestorrelseVenstre?: components['schemas']['KodeverkType'];
      kjoringSide?: string;
      oppbygningUnderstellsnummer?: string;
      overhengBak?: number;
      pabyggsKode?: components['schemas']['KodeverkType'];
      passasjerHandtak?: string;
      plasseringAvDorer?: components['schemas']['KodeverkType'];
      plasseringFabrikasjonsplate?: components['schemas']['KodeverkType'][];
      plasseringMerkeplateTrimming?: string;
      plasseringUnderstellsnummer?: components['schemas']['KodeverkType'][];
      rFarge?: components['schemas']['KodeverkType'][];
      sikkerhetsseler?: string;
      styremekanismeArt?: string;
      temperaturregulertSkap?: boolean;
      vendbarForerplass?: boolean;
    };
    Kjennemerke: {
      fomTidspunkt?: string;
      kjennemerke?: string;
      kjennemerkekategori?: 'KJORETOY' | 'NORMAL' | 'PERSONLIG' | 'PROVE';
      kjennemerketype?: components['schemas']['KodeverkType'];
      tilTidspunkt?: string;
    };
    Kjoretoydata: {
      kjoretoyId?: components['schemas']['ForenkletKjoretoyIdentitet'];
      forstegangsregistrering?: components['schemas']['Forstegangsregistrering'];
      kjennemerke?: components['schemas']['Kjennemerke'][];
      registrering?: components['schemas']['Registrering'];
      godkjenning?: components['schemas']['Godkjenning'];
      periodiskKjoretoyKontroll?: components['schemas']['PeriodiskKjoretoyKontroll'];
    };
    KjoretoydataResponse: {
      feilmelding?: string;
      kjoretoydataListe?: components['schemas']['Kjoretoydata'][];
    };
    Kjoretoyklassifisering: {
      beskrivelse?: string;
      efTypegodkjenning?: components['schemas']['EFTypegodkjenningsId'];
      kjoretoyAvgiftsKode?: components['schemas']['KodeverkType'];
      nasjonalGodkjenning?: components['schemas']['NasjonaltGodkjenningsnummer'];
      spesielleKjennetegn?: string;
      tekniskKode?: components['schemas']['KodeverkType'];
      tekniskUnderkode?: components['schemas']['KodeverkType'];
      iSamsvarMedTypegodkjenning?: boolean;
    };
    Kjoretoymerknad: {
      merknad?: string;
      merknadtypeKode?: string;
    };
    KodeverkType: {
      kodeBeskrivelse?: string;
      kodeNavn?: string;
      kodeTypeId?: string;
      kodeVerdi?: string;
      tidligereKodeVerdi?: string[];
    };
    Kopling: {
      avstandFremstePktTilSenterKopling?: number;
      avstandSenterKoplingTilBakerstePkt?: number;
      avstandSenterKoplingTilForsteAksel?: number;
      avstandSisteAkselTilKingpinMaks?: number;
      avstandSisteAkselTilKingpinMin?: number;
      avstandSisteAkselTilSenterKopling?: number;
      belastningDverdi?: number;
      belastningLoddrettMaks?: number;
      belastningSverdi?: number;
      belastningUverdi?: number;
      belastningVannrettMaks?: number;
      belastningVverdi?: number;
      eftype?: string;
      fabrikantKopling?: string;
      handelsbetegnelseKopling?: string;
      type?: components['schemas']['KodeverkType'];
    };
    Korreksjon: {
      godkjenningErKorrigert?: boolean;
      virkningsdato?: string;
      felterEndret?: string[];
    };
    Krav: {
      kravomrade?: components['schemas']['KodeverkType'];
      kravoppfyllelse?: components['schemas']['KodeverkType'];
      vognkortmerknad?: string;
    };
    LarevognGodkjenning: {
      forekortklasser?: components['schemas']['KodeverkType'];
      larevogn?: components['schemas']['KodeverkType'];
    };
    Lyd: {
      innvendigStoyniva?: number;
      kjorestoy?: number;
      standstoy?: number;
      stoyMalingOppgittAv?: components['schemas']['KodeverkType'];
      vedAntallOmdreininger?: number;
    };
    Merke: {
      merke?: string;
      merkeKode?: string;
    };
    MiljoOgDrivstoffGruppe: {
      drivstoffKodeMiljodata?: components['schemas']['KodeverkType'];
      forbrukOgUtslipp?: components['schemas']['ForbrukOgUtslipp'][];
      lyd?: components['schemas']['Lyd'];
    };
    Miljodata: {
      co2BesparelsePgaOkoInnovasjon?: number;
      euroKlasse?: components['schemas']['KodeverkType'];
      lyddemperUtblas?: string;
      miljoOgdrivstoffGruppe?: components['schemas']['MiljoOgDrivstoffGruppe'][];
      okoInnovasjon?: boolean;
      typeOkoInnovasjon?: string;
    };
    Motor: {
      antallSylindre?: number;
      arbeidsprinsipp?: components['schemas']['KodeverkType'];
      avgassResirkulering?: boolean;
      blandingsDrivstoff?: string;
      drivstoff?: components['schemas']['Drivstoff'][];
      fabrikant?: string;
      fordampningsutslippKontrollSystem?: boolean;
      katalysator?: boolean;
      kjolesystem?: string;
      ladeluftkjoler?: boolean;
      luftInnsproytning?: boolean;
      motorKode?: string;
      motorNummer?: string;
      oksygenSensor?: boolean;
      overladet?: boolean;
      partikkelfilterMotor?: boolean;
      slagvolum?: number;
      sylinderArrangement?: components['schemas']['KodeverkType'];
    };
    MotorOgDrivverk: {
      antallGir?: number;
      antallGirBakover?: number;
      effektKraftuttakKW?: number;
      girPlassering?: string;
      girkassetype?: components['schemas']['KodeverkType'];
      giroverforingsType?: string;
      girutvekslingPrGir?: components['schemas']['Girutveksling'][];
      hybridElektriskKjoretoy?: boolean;
      hybridKategori?: components['schemas']['KodeverkType'];
      maksimumHastighet?: number[];
      maksimumHastighetMalt?: number[];
      motor?: components['schemas']['Motor'][];
      obd?: boolean;
      totalUtvekslingHoyesteGir?: number;
      utelukkendeElektriskDrift?: boolean;
    };
    NasjonaltGodkjenningsnummer: {
      nasjonaltGodkjenningsAr?: string;
      nasjonaltGodkjenningsHovednummer?: string;
      nasjonaltGodkjenningsUndernummer?: string;
    };
    OppbygdMedAvgiftsfritak: {
      arkivreferanse?: string[];
      delekjoretoy?: components['schemas']['TekniskKjoretoyIdentitet'][];
      erstattetKjoretoy?: components['schemas']['TekniskKjoretoyIdentitet'];
    };
    OvrigeTekniskeData: {
      datafeltIndeks?: number;
      datafeltNavn?: string;
      datafeltVerdi?: string;
    };
    PeriodiskKjoretoyKontroll: {
      kontrollfrist?: string;
      sistGodkjent?: string;
    };
    Persontall: {
      bareplasser?: number;
      rullestolplasser?: number;
      sitteplassListe?: components['schemas']['SitteplassListe'];
      sitteplasserForan?: number;
      sitteplasserNede?: number;
      sitteplasserOppe?: number;
      sitteplasserStillstand?: number;
      sitteplasserTotalt?: number;
      sitteplasserTotaltSomVarebilKlasse2?: number;
      staplasser?: number;
    };
    Registrering: {
      fomTidspunkt?: string;
      kjoringensArt?: components['schemas']['KodeverkType'];
      neringskode?: string;
      neringskodeBeskrivelse?: string;
      registreringsstatus?: components['schemas']['KodeverkType'];
      registrertForstegangPaEierskap?: string;
      tilTidspunkt?: string;
      vektarsavgiftOppgittGrunnlag?: components['schemas']['VektarsavgiftOppgittGrunnlag'];
    };
    Registreringsbegrensninger: {
      registreringsbegrensning?: components['schemas']['KodeverkType'][];
    };
    Sitteplass: {
      beltekraftbegrenser?: boolean;
      beltestrammer?: boolean;
      frontairbag?: boolean;
      hodegardinairbag?: boolean;
      kneairbag?: boolean;
      posisjon?: string;
      rad?: number;
      sideairbag?: boolean;
    };
    SitteplassListe: {
      sitteplass?: components['schemas']['Sitteplass'][];
    };
    TekniskGodkjenning: {
      godkjenningsId?: string;
      godkjenningsundertype?: components['schemas']['KodeverkType'];
      gyldigFraDato?: string;
      gyldigFraDatoTid?: string;
      kjoretoyklassifisering?: components['schemas']['Kjoretoyklassifisering'];
      korreksjon?: components['schemas']['Korreksjon'];
      krav?: components['schemas']['Krav'][];
      tekniskeData?: components['schemas']['TekniskeData'];
      unntak?: components['schemas']['Unntak'][];
    };
    TekniskKjoretoyIdentitet: {
      kuid?: string;
      understellsbasertId?: components['schemas']['UnderstellsbasertId'];
    };
    TekniskeData: {
      akslinger?: components['schemas']['AkselInfo'];
      bremser?: components['schemas']['Bremse'];
      dekkOgFelg?: components['schemas']['DekkOgFelg'];
      dimensjoner?: components['schemas']['Dimensjoner'];
      generelt?: components['schemas']['Generelt'];
      karosseriOgLasteplan?: components['schemas']['KarosseriOgLasteplan'];
      miljodata?: components['schemas']['Miljodata'];
      motorOgDrivverk?: components['schemas']['MotorOgDrivverk'];
      ovrigeTekniskeData?: components['schemas']['OvrigeTekniskeData'][];
      persontall?: components['schemas']['Persontall'];
      tilhengerkopling?: components['schemas']['Tilhengerkopling'];
      vekter?: components['schemas']['Vekter'];
    };
    Tilhengerkopling: {
      kopling?: components['schemas']['Kopling'][];
    };
    Tilleggsgodkjenning: {
      godkjenningstype?: components['schemas']['KodeverkType'];
      godkjentFra?: string;
      godkjentFraDatoTid?: string;
      godkjentTil?: string;
      godkjentTilDatoTid?: string;
      korreksjon?: components['schemas']['Korreksjon'];
      krav?: components['schemas']['Krav'][];
      tilleggsgodkjenningSpesifikkeData?: components['schemas']['TilleggsgodkjenningSpesifikkeData'];
    };
    TilleggsgodkjenningSpesifikkeData: {
      adrGodkjenning?: components['schemas']['AdrGodkjenning'];
      dyretransportGodkjenning?: components['schemas']['DyretransportGodkjenning'];
      larevognGodkjenning?: components['schemas']['LarevognGodkjenning'];
    };
    Typegodkjenningsnummer: {
      direktiv?: string;
      land?: string;
      serie?: string;
      utvidelse?: string;
    };
    UnderstellsbasertId: {
      merkekode?: string;
      understellsnummer?: string;
    };
    Unntak: {
      merknad?: string[];
      unntak?: components['schemas']['KodeverkType'];
    };
    VektOgBremse: {
      bremseType?: string;
      vogntogvekt?: number;
    };
    VektarsavgiftOppgittGrunnlag: {
      antallAkslerTilhenger?: number;
      totalvektTilhenger?: number;
    };
    Vekter: {
      egenvekt?: number;
      egenvektMaksimum?: number;
      egenvektMinimum?: number;
      egenvektTilhengerkopling?: number;
      frontOgHjulVekter?: string;
      nyttelast?: number;
      tekniskTillattForhoyetTotalvekt?: number;
      tekniskTillattTotalvekt?: number;
      tekniskTillattTotalvektVeg?: number;
      tekniskTillattVektPahengsvogn?: number;
      tekniskTillattVektSemitilhenger?: number;
      tillattHjulLastSidevogn?: number;
      tillattTaklast?: number;
      tillattTilhengervektMedBrems?: number;
      tillattTilhengervektUtenBrems?: number;
      tillattTotalvekt?: number;
      tillattVektSlepevogn?: number;
      tillattVertikalKoplingslast?: number;
      tillattVogntogvekt?: number;
      tillattVogntogvektVeg?: number;
      vogntogvektAvhBremsesystem?: components['schemas']['VektOgBremse'][];
    };
    WLTP: {
      co2EkstraHoy?: number;
      co2Hoy?: number;
      co2Kombinert?: number;
      co2Lav?: number;
      co2Middels?: number;
      co2VektetKombinert?: number;
      forbrukEkstraHoy?: number;
      forbrukHoy?: number;
      forbrukKombinert?: number;
      forbrukLav?: number;
      forbrukMiddels?: number;
      forbrukVektetKombinert?: number;
      rekkeviddeKmBlandetkjoring?: number;
      rekkeviddeKmBykjoring?: number;
    };
  };
}

export interface operations {
  /** Henter kjoretoydata basert på understellsnummer, kjennemerke, eller personlig kjennemerke */
  hentKjoretoydata: {
    parameters: {
      query: {
        /** Kjøretøyets understellsnummer */
        understellsnummer?: string;
        /** Kjøretøyets kjennemerke eller personlige kjennemerke */
        kjennemerke?: string;
      };
    };
    responses: {
      /** default response */
      200: {
        content: {
          '*/*': components['schemas']['KjoretoydataResponse'];
        };
      };
    };
  };
}

// Alt over her er automatisk generert av openapi-typescript. Under dette er en håndskrevet symfoni skrevet av Mozart sjøl.

type VehicleInfoResponse = components['schemas']['KjoretoydataResponse'];
type VehicleInfo = components['schemas']['Kjoretoydata'];
interface RegistrationInfo {
  registrationNumber?: string;
  vinNumber?: string;
  merkeKode?: string;
  merkenavn?: string;
  modellnavn?: string;
  regDato?: string;
  isBruktimportert?: boolean;
  forstegangRegDatoNorge?: string;
  forstegangRegDatoNorgeAsDate?: DateTime;
  sistePkk?: string;
  nestePkk?: string;
  drivstoff?: string;
  isHybrid?: boolean;
  hybridKategori?: string;
  slagvolum?: string;
  motorytelse?: string;
  totalVekt?: number;
  nyttelast?: number;
  egenvekt?: number;
  tillattVogntogvekt?: number;
  tillatTaklast?: number;
  antallAksler?: number;
  antallAkslerMedDrift?: number;
  aksel1DekkDim?: string;
  aksel2DekkDim?: string;
  aksel1FelgDim?: string;
  aksel2FelgDim?: string;
  farge?: string;
  girkasse?: string;
  hjuldrift?: string;
  antallSitteplasser?: number;
  antallDorer?: number;
  bilType?: string;
  motorEffektHK?: number;
}

export type { VehicleInfo, VehicleInfoResponse, RegistrationInfo };

export const getIsZeroEmission: (vehicleInfo?: VehicleInfo) => boolean = (vehicleInfo?: VehicleInfo) =>
  vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeNavn === '0-utslipp' ?? false;

export const getCo2Score: (vehicleInfo?: VehicleInfo) => number = (vehicleInfo?: VehicleInfo) => {
  const co2BlandetKjoring =
    vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.flatMap(
      (m) => m.forbrukOgUtslipp?.map((f) => f.co2BlandetKjoring)
    );

  const vektetKombinert =
    vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.flatMap(
      (m) => m.forbrukOgUtslipp?.map((f) => f.vektetKombinertDrivstoffCO2)
    );

  const summertVektetKombinert = _.sum(vektetKombinert);

  if (getIsZeroEmission(vehicleInfo)) {
    return 0;
  } else if (summertVektetKombinert > 0) {
    return summertVektetKombinert;
  } else {
    return _.sum(co2BlandetKjoring);
  }
};

export const getNoxScore = (vehicleInfo?: VehicleInfo) => {
  if (getIsZeroEmission(vehicleInfo)) {
    return 0;
  }

  const noxVerdier =
    vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.flatMap(
      (m) => m.forbrukOgUtslipp?.map((f) => f.utslippNOxMgPrKm)
    );

  return _.sum(noxVerdier);
};

function getDrivstoff(vehicleInfo?: VehicleInfo) {
  const motor = vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor;
  const listOfEngines = motor?.flatMap((m) => m.drivstoff?.map((d) => d.drivstoffKode?.kodeNavn));

  if (listOfEngines?.every((x) => x === 'Elektrisk')) return 'Elektrisk';
  if (listOfEngines?.every((x) => x === 'Hydrogen')) return 'Hydrogen';

  const fossilFuelType = listOfEngines?.includes('Diesel') ? 'Diesel' : 'Bensin';
  const hybridKategori =
    vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.hybridKategori?.kodeVerdi?.toUpperCase();
  switch (hybridKategori) {
    case 'LADBAR':
      return `${fossilFuelType} Plug-in`;
    case 'ANNEN':
    case 'IKKE_LADBAR':
      return `${fossilFuelType}hybrid`;
    default:
      return fossilFuelType;
  }
}

export const getRegistrationInfo: (vehicleInfo?: VehicleInfo) => RegistrationInfo = (vehicleInfo?: VehicleInfo) => {
  const kjoretoyId = vehicleInfo?.kjoretoyId;
  const merke = _.head(vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke);

  const handelsbetegnelse = _.head(
    vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse
  );

  const forstegangsGodkjenning = vehicleInfo?.godkjenning?.forstegangsGodkjenning;
  const motor = vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor;

  const vekter = vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.vekter;

  const akslinger = vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.akslinger;
  const akselDekkOgFelgKombinasjon =
    vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.dekkOgFelg?.akselDekkOgFelgKombinasjon?.flatMap(
      (a) => a.akselDekkOgFelg
    );

  const groupedAkselDekkOgFelg = _.groupBy(akselDekkOgFelgKombinasjon, (x) => x?.akselId);

  const antallAkslerMedDrift = akslinger?.akselGruppe
    ?.flatMap((a) => a.akselListe?.aksel?.map((ak) => !!ak.drivAksel))
    .filter((x) => !!x).length;

  let hjuldrift: string;
  if (antallAkslerMedDrift === akslinger?.antallAksler) {
    hjuldrift = 'Firehjulsdrift';
  } else if (
    akslinger?.akselGruppe?.flatMap((a) => a.akselListe?.aksel)?.some((a) => a?.drivAksel && a?.plasseringAksel === '2')
  ) {
    hjuldrift = 'Bakhjulsdrift';
  } else {
    hjuldrift = 'Forhjulsdrift';
  }

  const motorEffekterKW = vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.flatMap(
    (m) => m.drivstoff?.map((d) => d.maksNettoEffekt)
  );

  return {
    registrationNumber: kjoretoyId?.kjennemerke?.replaceAll(' ', ''),
    vinNumber: kjoretoyId?.understellsnummer,
    merkeKode: merke?.merkeKode,
    merkenavn: merke?.merke,
    modellnavn: handelsbetegnelse,
    regDato: forstegangsGodkjenning?.forstegangRegistrertDato,
    isBruktimportert: !!forstegangsGodkjenning?.bruktimport,
    forstegangRegDatoNorge: vehicleInfo?.forstegangsregistrering?.registrertForstegangNorgeDato,
    forstegangRegDatoNorgeAsDate: vehicleInfo?.forstegangsregistrering?.registrertForstegangNorgeDato
      ? DateTime.fromFormat(vehicleInfo?.forstegangsregistrering?.registrertForstegangNorgeDato, 'yyyy-MM-dd')
      : undefined,
    sistePkk: vehicleInfo?.periodiskKjoretoyKontroll?.sistGodkjent,
    nestePkk: vehicleInfo?.periodiskKjoretoyKontroll?.kontrollfrist,
    drivstoff: getDrivstoff(vehicleInfo),
    isHybrid: vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.hybridElektriskKjoretoy,
    hybridKategori:
      vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.hybridKategori?.kodeNavn,
    slagvolum: motor
      ?.map((m) => m.slagvolum)
      .filter((s) => !!s)
      .join(', '),
    motorytelse: motor?.flatMap((m) => m.drivstoff?.map((d) => d.maksNettoEffekt)).join(', '),
    totalVekt: vekter?.tillattTotalvekt,
    nyttelast: vekter?.nyttelast,
    egenvekt: vekter?.egenvekt,
    tillattVogntogvekt: vekter?.tillattVogntogvekt,
    tillatTaklast: vekter?.tillattTaklast,
    antallAksler: akslinger?.antallAksler,
    antallAkslerMedDrift: antallAkslerMedDrift,
    aksel1DekkDim: groupedAkselDekkOgFelg[1] && groupedAkselDekkOgFelg[1].map((a) => a?.dekkdimensjon).join('*'),
    aksel2DekkDim: groupedAkselDekkOgFelg[2] && groupedAkselDekkOgFelg[2].map((a) => a?.dekkdimensjon).join('*'),
    aksel1FelgDim: groupedAkselDekkOgFelg[1] && groupedAkselDekkOgFelg[1].map((a) => a?.felgdimensjon).join('*'),
    aksel2FelgDim: groupedAkselDekkOgFelg[2] && groupedAkselDekkOgFelg[2].map((a) => a?.felgdimensjon).join('*'),
    farge: vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.karosseriOgLasteplan?.rFarge
      ?.map((f) => f.kodeNavn)
      .join(','),
    girkasse: vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeNavn,
    hjuldrift: hjuldrift,
    antallDorer: _.sum(vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.karosseriOgLasteplan?.antallDorer),
    antallSitteplasser: vehicleInfo?.godkjenning?.tekniskGodkjenning?.tekniskeData?.persontall?.sitteplasserTotalt,
    bilType: vehicleInfo?.godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.tekniskKode?.kodeNavn,
    motorEffektHK: _.sum(motorEffekterKW) * 1.34102,
  };
};
