import { Grid, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getSavedAndSortedCars } from './atoms/savedCars';
import CarSorting from './CarSorting';
import styles from './SavedCars.module.css';
import {
  getAccountingInfoForCarToday,
  getAccountingInfoForOppkjoperCar,
  getAuctionEndDate,
  getCarAgeInDays,
  getLeasingAccountingInfoForCarToday,
  getLeasingAccountingInfoForCarWhenItIsSold,
} from './types/Car';
import { userHasOppkjoperRole } from './atoms/auth';
import { CarCard } from './CarCard';
import { DateTime } from 'luxon';

const numberFormat = Intl.NumberFormat('no-NO', {
  style: 'currency',
  currency: 'NOK',
  maximumSignificantDigits: 10,
});

const SavedCars = () => {
  const cars = useRecoilValue(getSavedAndSortedCars);
  const isOppkjoper = useRecoilValue(userHasOppkjoperRole);

  if (!cars || cars.length === 0) {
    return null;
  }

  const relevantCars = cars.filter((car) => car.contractPrice && !car.isSold);

  const financialGroups = _.groupBy(relevantCars, (x) => x.financialCategory);

  const accountingInfos = financialGroups['Egeneid']?.map((car) => getAccountingInfoForCarToday(car)) ?? [];
  const leasingAccountingInfosToday = financialGroups['Leaset']?.map((car) => getLeasingAccountingInfoForCarToday(car));
  const leasingAccountingInfos = financialGroups['Leaset']?.map((car) =>
    getLeasingAccountingInfoForCarWhenItIsSold(car)
  );

  const numberOfRelevantCars = relevantCars.length;
  const sumOfPrices = _.sumBy(relevantCars, (c) => c.price ?? 0);
  const sumOfBalanceValues = _.sumBy(accountingInfos, (x) => x.accountingValue);
  const sumOfLeasingBalanceValuesToday = _.sumBy(leasingAccountingInfosToday, (x) => x.totalPriceToBuyOutCar);
  const sumOfLeasingBalanceValues = _.sumBy(leasingAccountingInfos, (x) => x.totalPriceToBuyOutCar);
  const sumOfSalesMargins =
    _.sumBy(accountingInfos, (x) => x.salesMargin) + _.sumBy(leasingAccountingInfos, (x) => x.salesMarginLeasing);
  const averageMargin = Math.floor(sumOfSalesMargins / numberOfRelevantCars);

  const averageMarginInPercentage =
    (averageMargin / ((sumOfBalanceValues + sumOfLeasingBalanceValues) / numberOfRelevantCars)) * 100;
  const totalAge = _.sum(relevantCars.map((car) => getCarAgeInDays(car)));
  const averageAge = Math.floor(totalAge / numberOfRelevantCars);

  const unsoldRelevantOppkjoperCars = cars.filter((c) => !c.isSold);
  const oppkjoperCarsWithNecessaryFields = unsoldRelevantOppkjoperCars.filter(
    (c) => !!c.price && !!c.pricePaidForOppkjoperCar
  );
  const sumOfOppkjoperPrices = _.sumBy(oppkjoperCarsWithNecessaryFields, (c) => c.price ?? 0);
  const sumOfOppkjoperPricesPaid = _.sumBy(oppkjoperCarsWithNecessaryFields, (c) => c.pricePaidForOppkjoperCar ?? 0);
  const oppkjoperAccountingInfos = oppkjoperCarsWithNecessaryFields.map((c) => getAccountingInfoForOppkjoperCar(c));
  const sumOfOppkjoperBalanceValues = _.sumBy(oppkjoperAccountingInfos, (x) => x.accountingValue);
  const sumOfOppkjoperMargins = _.sumBy(oppkjoperAccountingInfos, (x) => x.salesMargin);
  const averageOppkjoperMargin =
    oppkjoperCarsWithNecessaryFields.length > 0 ? sumOfOppkjoperMargins / oppkjoperCarsWithNecessaryFields.length : 0;
  const averageOppkjoperMarginInPercentage =
    oppkjoperCarsWithNecessaryFields.length > 0
      ? (averageOppkjoperMargin / (sumOfOppkjoperBalanceValues / oppkjoperCarsWithNecessaryFields.length)) * 100
      : 0;

  const numberOfOppkjoperCarsExcludedDueToBeingSold = cars.length - unsoldRelevantOppkjoperCars.length;
  const numberOfOppkjoperCarsExcludedDueToMissingData =
    unsoldRelevantOppkjoperCars.length - oppkjoperCarsWithNecessaryFields.length;

  const carsWithActiveAuctions = cars
    .filter((car) => !car.isSold)
    .filter((car) => getAuctionEndDate(car) > DateTime.local() || !isOppkjoper);
  const carsWithFinishedAuctions = cars
    .filter((car) => !car.isSold)
    .filter((car) => getAuctionEndDate(car) <= DateTime.local() || !isOppkjoper);
  const soldCars = cars.filter((car) => car.isSold);
  return (
    <>
      {!isOppkjoper && (
        <Paper className={styles.formPaper}>
          <div className={styles['stats-container']}>
            <div className={styles['statistics-container']}>
              <Typography variant="h2" className={styles.paperHeader}>
                Statistikk
              </Typography>
              <Grid item md={6} xs={12}>
                <div className={styles.summaryContainer}>
                  {!!sumOfBalanceValues && (
                    <div className={styles.calculationLine}>
                      <Typography>Bokført lagerverdi:</Typography>{' '}
                      <Typography>{`${numberFormat.format(sumOfBalanceValues)} (${accountingInfos.length} bil${
                        accountingInfos.length > 1 ? 'er' : ''
                      })`}</Typography>
                    </div>
                  )}
                  {!!leasingAccountingInfosToday && (
                    <div className={styles.calculationLine}>
                      <Typography>Dagens verdi av leasingbiler:</Typography>{' '}
                      <Typography>{`${numberFormat.format(Math.round(sumOfLeasingBalanceValuesToday))} (${
                        leasingAccountingInfosToday.length
                      } bil${leasingAccountingInfos.length > 1 ? 'er' : ''})`}</Typography>
                    </div>
                  )}
                  {isOppkjoper && (
                    <>
                      <div className={styles.calculationLine}>
                        <Typography>Lagerverdi: </Typography>{' '}
                        <Typography>{numberFormat.format(sumOfOppkjoperPricesPaid)}</Typography>
                      </div>
                      <div className={styles.calculationLine}>
                        <Typography>Sum minstepriser: </Typography>{' '}
                        <Typography>{numberFormat.format(sumOfOppkjoperPrices)}</Typography>
                      </div>
                      <div className={styles.calculationLine}>
                        <Typography className={styles.calculationLine}>Gj.snitt margin mot minstepris: </Typography>{' '}
                        <Typography>
                          {numberFormat.format(averageOppkjoperMargin)} /{' '}
                          {averageOppkjoperMarginInPercentage.toFixed(1)}%
                        </Typography>
                      </div>
                      {numberOfOppkjoperCarsExcludedDueToMissingData === 1 && (
                        <div className={styles.calculationDisclaimer}>
                          * Én bil er utelatt fra beregningen fordi den mangler enten minstepris eller innpris.
                        </div>
                      )}
                      {numberOfOppkjoperCarsExcludedDueToMissingData > 1 && (
                        <div className={styles.calculationDisclaimer}>
                          * {numberOfOppkjoperCarsExcludedDueToMissingData} biler er utelatt fra beregningen fordi de
                          mangler enten minstepris eller innpris.
                        </div>
                      )}
                      {numberOfOppkjoperCarsExcludedDueToBeingSold === 1 && (
                        <div className={styles.calculationDisclaimer}>
                          * Én bil er utelatt fra beregningen fordi den er solgt.
                        </div>
                      )}
                      {numberOfOppkjoperCarsExcludedDueToBeingSold > 1 && (
                        <div className={styles.calculationDisclaimer}>
                          * {numberOfOppkjoperCarsExcludedDueToBeingSold} biler er utelatt fra beregningen fordi de har
                          blitt solgt.
                        </div>
                      )}
                    </>
                  )}
                  {!isOppkjoper && !!sumOfPrices && (
                    <>
                      <div className={styles.calculationLine}>
                        <Typography>Sum utpriser: </Typography>{' '}
                        <Typography>{numberFormat.format(sumOfPrices)}</Typography>
                      </div>
                      <div className={styles.calculationLine}>
                        <Typography className={styles.calculationLine}>Gj.snitt margin: </Typography>{' '}
                        <Typography>
                          {numberFormat.format(averageMargin)} / {averageMarginInPercentage.toFixed(1)}%
                        </Typography>
                      </div>
                      <div className={styles.calculationLine}>
                        <Typography className={styles.calculationLine}>Gj.snitt alder: </Typography>{' '}
                        <Typography>{averageAge} dager</Typography>
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            </div>
          </div>
        </Paper>
      )}

      <Paper className={styles.formPaper}>
        <Typography variant="h2" className={styles.paperHeader}>
          {isOppkjoper
            ? `Aktive auksjoner (${carsWithActiveAuctions.length})`
            : `Publiserte biler (${carsWithActiveAuctions.length})`}
        </Typography>
        {!isOppkjoper && (
          <div className={styles.sortingContainer}>
            <CarSorting />
          </div>
        )}
        <div className={styles.carGrid}>
          {carsWithActiveAuctions.map((car) => (
            <CarCard car={car} key={car.vinNumber} />
          ))}
        </div>
      </Paper>

      {isOppkjoper && (
        <Paper className={styles.formPaper}>
          <Typography variant="h2" className={styles.paperHeader}>
            Avsluttede auksjoner ({carsWithFinishedAuctions.length})
          </Typography>
          <div className={styles.carGrid}>
            {carsWithFinishedAuctions.map((car) => (
              <CarCard car={car} key={car.vinNumber} />
            ))}
          </div>
        </Paper>
      )}
      {soldCars.length > 0 && (
        <Paper className={styles.formPaper}>
          <Typography variant="h2" className={styles.paperHeader}>
            Solgte biler ({soldCars.length})
          </Typography>
          <div className={styles.carGrid}>
            {soldCars.map((car) => (
              <CarCard car={car} key={car.vinNumber} />
            ))}
          </div>
        </Paper>
      )}
    </>
  );
};

export default SavedCars;
