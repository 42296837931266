import { atom, selector } from 'recoil';
import { authToken } from './auth';

export const userRequestId = atom({
  key: 'UserRequestID',
  default: 0,
});

export const usersInRolePage = atom({
  key: 'usersInRolePage',
  default: 0,
});

export const getUsersWithRelevantRole = selector<UserResponse | undefined>({
  key: 'GetUsers',
  get: async ({ get }) => {
    get(userRequestId);
    const page = get(usersInRolePage);
    const token = get(authToken)?.__raw;
    if (!token) return;
    const response = await fetch(`/nf-functions/get-users?page=${page}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return (await response.json()) as UserResponse;
  },
});

export interface UserResponse {
  users: UserInRole[];
  start: number;
  limit: number;
  total: number;
}

export interface UserInRole {
  userId: string;
  email: string;
  name: string;
}
