import { atom, selector } from 'recoil';
import { IdToken } from '@auth0/auth0-react';
import { getLocations, isOppkjoperRole } from '../dealerships';
import { getRole } from '../authUtils';

export const authToken = atom<IdToken | undefined>({
  key: 'AuthToken',
  default: undefined,
});

const role = selector<string>({
  key: 'role',
  get: ({ get }) => {
    const token = get(authToken);
    if (!token) return '';
    return getRole(token);
  },
});

export const dealershipLocations = selector<string[]>({
  key: 'DealershipLocations',
  get: ({ get }) => getLocations(get(role)),
});

export const userHasOppkjoperRole = selector<boolean>({
  key: 'userHasOppkjoperRole',
  get: ({ get }) => isOppkjoperRole(get(role)),
});

export const auctionHostname = selector<string | null>({
  key: 'auctionHostname',
  get: ({ get }) => {
    switch (get(role)) {
      case 'frydenboOppkjoperAdmin':
        return 'frydenbo-oppkjoper.bruktbil.shop';
      case 'hyundaiAuksjonAdmin':
        return 'hyundai.bruktbil.shop';
      case 'autotalAuksjonAdmin':
        return 'autotal-auksjon.bruktbil.shop';
      case 'testOppkjoperAdmin':
        return 'oppkjoper.bruktbil.shop';
      default:
        return null;
    }
  },
});
