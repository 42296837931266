import { DateTime } from 'luxon';
import { atom, selector, selectorFamily } from 'recoil';
import { FaunaDocument } from '../types/FaunaDocument';
import { authToken } from './auth';
import { Car, ListCar } from '../types/Car';

export const requestId = atom({
  key: 'RequestID',
  default: 0,
});

export interface sortOrderState {
  selector: (car: ListCar) => string | number | DateTime;
  reverse: boolean;
  name: string;
}

export const sortOrder = atom<sortOrderState>({
  key: 'carSortOrder',
  default: {
    selector: (car: ListCar) => car.title,
    reverse: false,
    name: 'name',
  },
});

export const getSavedCars = selector<ListCar[] | undefined>({
  key: 'GetSavedCars',
  get: async ({ get }) => {
    get(requestId);
    const token = get(authToken)?.__raw;
    if (!token) return;
    const response = await fetch('/nf-functions/get-cars', {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return (await response.json()).data as ListCar[];
  },
});

export const getSavedAndSortedCars = selector<ListCar[] | undefined>({
  key: 'GetSavedAndSortedCars',
  get: async ({ get }) => {
    const cars = get(getSavedCars);
    const sort = get(sortOrder);
    if (!cars) return undefined;

    return cars.slice().sort((a, b) => {
      const aSide = sort.selector(a);
      const bSide = sort.selector(b);

      if (aSide > bSide) return !sort.reverse ? 1 : -1;
      if (bSide > aSide) return !sort.reverse ? -1 : 1;
      return 0;
    });
  },
});

export const getSavedCar = selectorFamily<FaunaDocument<Car> | undefined, string>({
  key: 'GetSingleSavedCar',
  get:
    (carId: string) =>
    async ({ get }) => {
      get(requestId);
      const token = get(authToken)?.__raw;
      if (!token) return;
      const response = await fetch(`/nf-functions/get-car/${carId}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return (await response.json()) as FaunaDocument<Car>;
    },
});
