import React, { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Link } from 'react-router-dom';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import c from './SoldCarsTable.module.css';
import _ from 'lodash';
import { getAuctionEndDate, ListCar } from './types/Car';
import { AutoImage } from './CloudinaryImageComponents';
import { ArrowDownward } from '@mui/icons-material';

const cx = require('classnames');

const currencyNumberFormat = Intl.NumberFormat('no-NO', {
  style: 'currency',
  currency: 'NOK',
  maximumSignificantDigits: 10,
});

interface SortState {
  fieldSelector: (car: ListCar) => any;
  reverse: boolean;
}

const selectors = {
  title: (car: ListCar) => car.title,
  regNr: (car: ListCar) => car.regNumber,
  location: (car: ListCar) => car.location,
  auctionEndDate: (car: ListCar) => car.auctionEndDate,
  numberOfBids: (car: ListCar) => car.numberOfBids,
  highestBidder: (car: ListCar) => car.highestBid.user?.company,
  finalPrice: (car: ListCar) => car.finalPrice,
  pricePaidForOppkjoperCar: (car: ListCar) => car.pricePaidForOppkjoperCar,
  margin: (car: ListCar) =>
    (!!car.finalPrice && !!car.pricePaidForOppkjoperCar && car.finalPrice - car.pricePaidForOppkjoperCar) || 0,
};

const defaultSortState = { fieldSelector: selectors.auctionEndDate, reverse: true };

export const SoldCarsTable: React.FC<{ cars: ListCar[] }> = ({ cars }) => {
  const [sortState, setSortState] = useState<SortState>(defaultSortState);

  const sortedCars = sortState.reverse
    ? _.sortBy(cars, sortState.fieldSelector).reverse()
    : _.sortBy(cars, sortState.fieldSelector);

  const getOnClick = (fieldSelector: (car: ListCar) => any) => () =>
    setSortState((prevState) =>
      prevState.fieldSelector === fieldSelector
        ? !prevState.reverse
          ? {
              fieldSelector: fieldSelector,
              reverse: true,
            }
          : defaultSortState
        : { fieldSelector: fieldSelector, reverse: false }
    );

  return (
    <TableContainer component={Paper}>
      <Table className={c.table}>
        <TableHead>
          <TableRow>
            <TableCell className={cx(c.cell, c.tablehead)} />
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.title)}>
              <div className={c.headerContainer}>
                Bil
                <SortArrow selector={selectors.title} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.regNr)}>
              <div className={c.headerContainer}>
                Reg.nr.
                <SortArrow selector={selectors.regNr} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.location)}>
              <div className={c.headerContainer}>
                Forhandler
                <SortArrow selector={selectors.location} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell
              className={cx(c.cell, c.tablehead)}
              onClick={() =>
                setSortState((prevState) => ({
                  fieldSelector: selectors.auctionEndDate,
                  reverse: prevState.fieldSelector === selectors.auctionEndDate && !prevState.reverse,
                }))
              }
            >
              <div className={c.headerContainer}>
                Auksjon avsluttet
                <SortArrow selector={selectors.auctionEndDate} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.numberOfBids)}>
              <div className={c.headerContainer}>
                Antall bud
                <SortArrow selector={selectors.numberOfBids} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.highestBidder)}>
              <div className={c.headerContainer}>
                Høyeste budgiver
                <SortArrow selector={selectors.highestBidder} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.finalPrice)}>
              <div className={c.headerContainer}>
                Oppnådd pris
                <SortArrow selector={selectors.finalPrice} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.pricePaidForOppkjoperCar)}>
              <div className={c.headerContainer}>
                Innkjøpspris
                <SortArrow selector={selectors.pricePaidForOppkjoperCar} sortState={sortState} />
              </div>
            </TableCell>
            <TableCell className={cx(c.cell, c.tablehead)} onClick={getOnClick(selectors.margin)}>
              <div className={c.headerContainer}>
                Margin
                <SortArrow selector={selectors.margin} sortState={sortState} />
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedCars
            .filter((car) => car.isSold)
            .map((car) => {
              const buyer = car.highestBid?.user;
              const costPrice = car.pricePaidForOppkjoperCar;

              return (
                <TableRow className={c.row} key={car.refId}>
                  <TableCell className={`${c.cell} ${c.imageCell}`}>
                    <Link className={c.link} to={`/edit/${car.refId}`}>
                      <AutoImage
                        publicId={car.mainImage?.id}
                        width={100}
                        crop="limit"
                        className={c.image}
                        alt={'Bilens hovedbilde'}
                      />
                    </Link>
                  </TableCell>
                  <TableCell className={`${c.cell} ${c.title}`}>
                    <Link className={c.link} to={`/edit/${car.refId}`}>
                      {car.title}
                    </Link>
                  </TableCell>

                  <TableCell className={c.cell}>{car.regNumber}</TableCell>
                  <TableCell className={c.cell}>{car.location}</TableCell>
                  <TableCell className={c.cell}>
                    {getAuctionEndDate(car).setLocale('no').toFormat('dd. MMM yyyy')}
                  </TableCell>
                  <TableCell className={c.cell}>{car.numberOfBids}</TableCell>
                  <TableCell className={c.cell}>{buyer?.company}</TableCell>
                  <TableCell className={c.cell}>
                    {car.finalPrice && currencyNumberFormat.format(car.finalPrice)}
                  </TableCell>
                  <TableCell className={c.cell}>{costPrice && currencyNumberFormat.format(costPrice)}</TableCell>
                  <TableCell className={c.cell}>
                    {car.finalPrice && costPrice && currencyNumberFormat.format(car.finalPrice - costPrice)}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SortArrow: React.FC<{ sortState: SortState; selector: (car: ListCar) => any }> = ({ sortState, selector }) => (
  <div className={c.arrowContainer}>
    <ArrowDownward
      className={cx(c.arrow, {
        [c.sortedBy]: sortState.fieldSelector === selector,
        [c.reverse]: sortState.reverse,
      })}
    />
  </div>
);
