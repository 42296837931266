import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { requestId } from '../atoms/savedCars';
import { cleanUpMakeName, cleanUpModelName } from '../SvvUtils';
import { FormCar } from '../types/FormCar';
import CreateCar from './createCar';
import { EditCarForm } from './EditCarForm';
import { getSvvCar, RegNumber } from '../atoms/svvInfo';
import { getRegistrationInfo } from '../types/kjoretoyopplysninger';
import { userHasOppkjoperRole } from '../atoms/auth';
import Loader from '../Loader';
import { Car } from '../types/Car';
import Prompt from '../Prompt';

const CreateCarPage = () => {
  const params = useParams<{ id: string }>();
  const regNumberFromUrl = params.id;
  const isOppkjoper = useRecoilValue(userHasOppkjoperRole);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);

  const [SVVInput, setSVVInput] = useRecoilState(RegNumber);
  const { enqueueSnackbar } = useSnackbar();
  const { getIdTokenClaims } = useAuth0();

  const { register, handleSubmit, control, watch, setValue, getValues, formState } = useForm<FormCar>({
    defaultValues: {
      carCategory: isOppkjoper ? undefined : 'Demobil',
      financialCategory: isOppkjoper ? undefined : 'Egeneid',
      mileage: isOppkjoper ? undefined : 4000,
      equipmentList: isOppkjoper ? [] : ['Vinterhjul'],
      adText: '',
      extraPhotos: [],
      mainImage: undefined,
    },
  });

  const { errors } = formState;

  const refresh = useSetRecoilState(requestId);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (regNumberFromUrl !== SVVInput) {
      setSVVInput(regNumberFromUrl ?? '');
    }
  }, [SVVInput, regNumberFromUrl, setSVVInput]);

  const svvResult = useRecoilValue(getSvvCar);
  const car = svvResult?.kind === 'success' ? svvResult.response : undefined;

  const registrationInfo = getRegistrationInfo(car);

  useEffect(() => {
    if (formState.isDirty && !hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }

    if (formState.isSubmitSuccessful) {
      setHasUnsavedChanges(false);
      setTimeout(() => {
        refresh((x) => x + 1);
        navigate('/');
      }, 0);
    }
  }, [formState, refresh, navigate, setHasUnsavedChanges, hasUnsavedChanges]);

  const onSubmit = async (data: FormCar) => {
    if (!car) return;
    const token = await getIdTokenClaims();
    data.title = getValues().title;
    await CreateCar(data, token?.__raw || '', registrationInfo, enqueueSnackbar);
  };

  const deliveryDate = registrationInfo.forstegangRegDatoNorgeAsDate
    ? registrationInfo.forstegangRegDatoNorgeAsDate.plus({ months: 4 })
    : DateTime.local().plus({ months: 4 });

  const randomMinute = Math.floor(Math.random() * 60);

  let suggestedEndDate = DateTime.local().plus({ days: 4 });
  while (suggestedEndDate.weekday > 5) suggestedEndDate = suggestedEndDate.plus({ days: 1 });

  const auctionEndDate = suggestedEndDate.set({ hour: 12, minute: randomMinute, second: 0, millisecond: 0 });

  const [model, make] = watch(['model', 'make']);

  if (make && model) {
    const title = `${make} ${model}`;
    setValue('title', title);
  }

  if (!car) return <Loader />;

  const cleanMake = cleanUpMakeName(registrationInfo.merkenavn ?? 'Ukjent');
  const cleanModel = cleanUpModelName(registrationInfo.modellnavn ?? 'Ukjent', cleanMake);
  const carData: Car = {
    regNumber: SVVInput,
    carCategory: isOppkjoper ? undefined : 'Demobil',
    financialCategory: isOppkjoper ? undefined : 'Egeneid',
    make: cleanMake,
    model: cleanModel,
    title: `${cleanMake} ${cleanModel}`,
    modelYear: Number(registrationInfo.forstegangRegDatoNorgeAsDate?.year ?? new Date().getFullYear().toString()),
    registrationDate: registrationInfo.forstegangRegDatoNorgeAsDate?.toISODate() ?? undefined,
    deliveryDate: isOppkjoper ? undefined : deliveryDate,
    auctionEndDate: isOppkjoper ? auctionEndDate : undefined,
    mileage: isOppkjoper ? undefined : 4000,
    equipmentList: isOppkjoper ? [] : ['Vinterhjul'],
    adText: '',
    extraPhotos: [],
    vinNumber: registrationInfo.vinNumber ?? 'Ukjent',
    transmission: registrationInfo.girkasse ?? 'Ukjent',
    numberOfDoors: registrationInfo.antallDorer?.toString() ?? 'Ukjent',
    numberOfSeats: registrationInfo.antallSitteplasser?.toString() ?? 'Ukjent',
    color: registrationInfo.farge ?? 'Ukjent',
    fuelType: registrationInfo.drivstoff ?? 'Ukjent',
    carType: registrationInfo.bilType ?? null,
    engineEffect: registrationInfo.motorEffektHK?.toFixed(0) ?? 'Ukjent',
    wheelDrive: registrationInfo.hjuldrift ?? 'Ukjent',
    location: '',
    weight: registrationInfo.egenvekt,
  };

  return (
    <>
      <Prompt hasUnsavedChanges={hasUnsavedChanges} />
      <EditCarForm
        carData={carData}
        onSubmit={handleSubmit(onSubmit)}
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        watch={watch}
        isDirty={formState.isDirty}
      />
    </>
  );
};

export default CreateCarPage;
