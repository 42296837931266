import { atom, selector } from 'recoil';
import { VehicleInfo, VehicleInfoResponse } from '../types/kjoretoyopplysninger';

export const RegNumber = atom<string>({
  key: 'svvInfoRegNumber',
  default: '',
});

type SVVSuccess = {
  kind: 'success';
  response: VehicleInfo;
};

type SVVError = {
  kind: 'error';
  error: string;
};

type SVVResult = SVVSuccess | SVVError | undefined;

export const getSvvCar = selector<SVVResult>({
  key: 'svvInfo',
  get: async ({ get }) => {
    const regNumber = get(RegNumber);
    if (!regNumber) return undefined;

    const response = await fetch(`/nf-functions/vehicleInfo/${regNumber}`);
    const vehicleInfoResponse = (await response.json()) as VehicleInfoResponse;

    if (vehicleInfoResponse.kjoretoydataListe && vehicleInfoResponse.kjoretoydataListe.length > 0) {
      const vehicleInfo = vehicleInfoResponse.kjoretoydataListe[0];
      return { kind: 'success', response: vehicleInfo };
    } else {
      if (vehicleInfoResponse.feilmelding) {
        return { kind: 'error', error: vehicleInfoResponse.feilmelding };
      } else {
        console.error(vehicleInfoResponse);
        return { kind: 'error', error: 'Fant ikke bilen hos SVV' };
      }
    }
  },
});
