import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider, Typography } from '@mui/material';
import { CloudinaryContext } from 'cloudinary-react';
import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styles from './app.module.css';
import { getRole } from './authUtils';
import { theme } from './Theme';
import { authToken } from './atoms/auth';
import { getDbCollection } from './dealerships';
import Loader from './Loader';
import { TopBar } from './TopBar';

const App = () => {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [token, setToken] = useRecoilState(authToken);

  React.useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((x) => {
        setToken(x);
      });
    } else {
      setToken(undefined);
    }
  }, [getIdTokenClaims, isAuthenticated, setToken]);

  if (isLoading) {
    return <Loader fullscreen />;
  }

  if (token && !getRole(token)) {
    return (
      <>
        <TopBar />
        <div style={{ padding: '5em' }}>
          <h1>Du har ikke tilgang</h1>
          <p>
            Din bruker har ikke fått tildelt en rolle. Kontakt hei@shortshift.no hvis du skal ha tilgang til denne
            nettsiden.
          </p>
        </div>
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScrollRestoration />
      <CloudinaryContext cloudName="hassel-software-as">
        <TopBar>
          <>
            {process.env.NODE_ENV === 'development' && token && (
              <h2 style={{ marginRight: 25, color: 'orange', textTransform: 'capitalize' }}>
                {getDbCollection(getRole(token))}
              </h2>
            )}
          </>
        </TopBar>
        <div className={styles.mainContainer}>
          {isAuthenticated && (
            <React.Suspense fallback={<Loader fullscreen />}>
              <Outlet />
            </React.Suspense>
          )}
          {!isAuthenticated && <Typography variant="body1">Logg inn for å forvalte dine internbiler.</Typography>}
        </div>
      </CloudinaryContext>
    </ThemeProvider>
  );
};

export default App;
